.App {
  text-align: center;
  padding: 10px;
}

/* Fonts */
@import url('https://fonts.googleapis.com/css?family=Poppins&display=swap');

/* Set font to Josefin Sans */
body {
  font-family: 'Poppins', sans-serif;
  background-color: #dfdede;
  font-smooth: auto;
}

p {
  font-size: 1rem;
}

h1 {
  font-size: 1.5rem;
}

/* Make mobile font sizes smaller */
@media (max-width: 600px) {
  p {
    font-size: .9rem;
  }

  h1 {
    font-size: 1.5rem;
  }
}

.react-photo-album--photo {
  box-shadow: 3px 3px 5px rgba(0,0,0,0.5);
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
